import styled from 'styled-components';
import ImgBg from '../../images/metalife.jpg';

export const HeroContainer = styled.div`
  background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
    url(${ImgBg});
  height: 100vh;
  background-position: center;
  background-size: cover;
`;

export const HeroContent = styled.div`
  height: calc(100vh - 80px);
  max-height: 100%;
  padding: 0rem calc((100vw - 1300px) / 2);
`;

export const HeroItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100vh;
  max-height: 100%;
  padding: 0 2rem;
  width: 650px;
  color: #fff;
  line-height: 1;
  font-weight: bold;

  @media screen and (max-width: 650px) {
    width: 100%;
  }
`;

export const HeroH1 = styled.h1`
  font-size: clamp(2rem, 10vw, 3rem);
  margin-bottom: 1rem;
  letter-spacing: 3px;
`;

export const HeroP = styled.p`
  font-size: clamp(1rem, 2vw, 3rem);
  font-weight: 100;
  margin-bottom: 2rem;
`;

export const HeroP1 = styled.div`
  font-size: clamp(1.2rem, 2vw, 1.2rem);
  font-weight: 300;
  margin-bottom: 2rem;
`;

export const HeroBtn = styled.button`
  font-size: 1rem;
  padding: 0.5rem 2rem;
  border: 1;
  border-radius: 40px;
  background: transparent;
  color: #fff;
  transition: 0.2s ease-out; 
  white-space: pre-line;
  margin-bottom: 2rem;

  &:hover {
    background: #fff;
    transition: 0.2s ease-out;
    cursor: pointer;
    color: #000;
  }
`;

export const HeroLink = styled.a`
  color: #fff;
  font-size: 24px;
`;

export const HeroIconDiv = styled.div`
  justify-content: center;
  color: #fff;
  line-height: 1;
  font-size: clamp(1.5rem, 2vw, 1.5rem);
  font-weight: 300;
`;

export const HeroIconIos = styled.div`
  justify-content: center;
  color: #fff;
  line-height: 1;
  font-size: clamp(1.5rem, 2vw, 1.5rem);
  font-weight: 300;
  float: left;
`;

export const HeroIconAndroid = styled.div`
  justify-content: center;
  color: #fff;
  line-height: 1;
  font-size: clamp(1.5rem, 2vw, 1.5rem);
  font-weight: 300;
  float: left;
  margin-left: 1rem;
`;