import React from "react";
import {
  PartnerContainer,
  PartnerWrapper,
  PartnerHeading,
  PartnerName,
  PartnerTitle,
  PartnerCard,
  PartnerImg,
  PartnerInfo,
} from "./Partner.style";

const Partner = ({ heading, data }) => {
  return (
    <PartnerContainer>
      <PartnerHeading>
        {heading}
      </PartnerHeading>
      <PartnerWrapper>
        {data.map((partner, index) => {
          return (
            <PartnerCard 
              key={index}
              href={partner.link}
              target="_blank"
              aria-label="Partner"
              rel="noopener noreferrer"
            >
              <PartnerInfo>
                <PartnerName>{partner.name}</PartnerName>
                <PartnerImg src={partner.img} alt={partner.alt} />
                <PartnerTitle>{partner.desc}</PartnerTitle>
              </PartnerInfo>
            </PartnerCard>
          );
        })}
      </PartnerWrapper>
    </PartnerContainer>
  );
};

export default Partner;
