import ex1 from "../../images/partner-estv1.png";
import ex2 from "../../images/partner-nftcircle.png";
import ex3 from "../../images/partner-readyplayer.png";
import ex4 from "../../images/partner-dimuto1.png";
import ex5 from "../../images/partner-nuls1.png";
import ex6 from "../../images/partner-gamestack.png";
import ex7 from "../../images/partner-skylab.webp";
import ex8 from "../../images/partner-sm.png";
import ex9 from "../../images/partner-mb.png";
import ex10 from "../../images/partner-proxy42.png";
import ex11 from "../../images/partner-kraze.png";

export const partnerData = [
  {
    img: ex1,
    alt: "ESTV",
    link: '//www.estv.co/',
  },
  {
    img: ex10,
    alt: "Proxy42",
    link: '//www.proxy42.com/',
  },
  {
    img: ex3,
    alt: "Ready Plaer",
    link: '//readyplayer.me/',
  },
  {
    img: ex8,
    alt: "SmartMesh",
    link: '//smartmesh.io/',
  },
  {
    img: ex2,
    alt: "NFT Circle",
    link: '//nftcircle.io/',
  },
  {
    img: ex9,
    alt: "MeshBox",
    link: '//meshbox.io/',
  },
  {
    img: ex11,
    alt: "Kraze Football",
    link: '//krazefootball.com/',
  },
  {
    img: ex5,
    alt: "NULS",
    link: '//nuls.io/',
  },
  {
    img: ex4,
    alt: "Dimuto",
    link: '//dimuto.io/',
  },
  {
    img: ex6,
    alt: "Gamestack",
    link: '//gamestack.ai/',
  },
  {
    img: ex7,
    alt: "SkyLab",
    link: '//skylabteam.com/',
  },
];
