import React from "react";
import {
  GraphicsContainer,
  GraphicsWrapper,
  GraphicsHeading,
  GraphicsTitle,
  GraphicsImg,
} from "./Graphics.style";

const Graphics = ({ data }) => {
  return (
    <GraphicsContainer>
      <GraphicsHeading>
        <span className="GradientText"> {data.heading}</span>
      </GraphicsHeading>
      <GraphicsTitle>{data.title}</GraphicsTitle>
      <GraphicsWrapper>
        <GraphicsImg src={data.img} alt={data.alt} />
      </GraphicsWrapper>
    </GraphicsContainer>
  );
};

export default Graphics;
