import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import GlobalStyle from './globalStyles';
import { Hero, Experience, People, Graphics, Partner, Footer } from './components';
import { experienceData } from './components/Experience/data';
import { partnerData } from './components/Partner/data';
import { graphicsData1 } from './components/Graphics/data';
import { teamData, advisorData } from './components/People/data';

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Hero />
      <Experience heading='Experience MetaLife' data={experienceData} />
      <Graphics data={graphicsData1} />
      <People heading='MetaLife Team' data={teamData} />
      <People heading='Advisory DAO' data={advisorData} />
      <Partner heading='Partners' data={partnerData} />
      <Footer />
    </Router>
  );
}

export default App;
