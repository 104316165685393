import styled from 'styled-components';

export const GraphicsContainer = styled.div`
  /* width: 100vw; */
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #150f0f;
  color: #fff;
`;

export const GraphicsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;

export const GraphicsImg = styled.img`
  padding-right: 0;
  border: 0;
  max-width: 100%;
  vertical-align: middle;
  display: inline-block;
  max-height: 500px;
`;

export const GraphicsHeading = styled.h1`
  font-size: clamp(2rem, 2.5vw, 3rem);
  text-align: center;
  color: #45E1D6;
`;

export const GraphicsHeading1 = styled.h1`
  font-size: clamp(2rem, 2.5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
  color: #45E1D6;
`;

export const GraphicsTitle = styled.h2`
  font-weight: 150;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 5rem;
`;
