import React from "react";
import MetaLifeLogo from "../../images/metalife-logo.png";
import { Nav, NavLink, NavIconLink, NavIcons } from "./Navbar.style";
import { FaTwitter, FaDiscord, FaReadme } from "react-icons/fa";

const Navbar = () => {
  return (
    <>
      <Nav>
        <NavLink to="/">
          <img src={MetaLifeLogo} alt='MetaLife'/>
        </NavLink>
        <NavIcons>
        <NavIconLink
            href="//docs.metalife.social/metalife "
            target="_blank"
            aria-label="About MetaLife"
            title="About MetaLife"
            rel="noopener noreferrer"
          >
            <FaReadme />
          </NavIconLink>
          <NavIconLink
            href="//www.discord.gg/Pwy6j63sTV"
            target="_blank"
            aria-label="Discord"
            title="Discord Server"
            rel="noopener noreferrer"
          >
            <FaDiscord />
          </NavIconLink>
          <NavIconLink
            href="//www.twitter.com/metalifesocial"
            target="_blank"
            aria-label="Twitter"
            title="Twitter"
            rel="noopener noreferrer"
          >
            <FaTwitter />
          </NavIconLink>
        </NavIcons>
              </Nav>
    </>
  );
};

export default Navbar;
