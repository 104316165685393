import React from "react";
import {
  FaFacebook,
  FaTwitter,
  FaLinkedin,
  FaDiscord,
  FaReadme,
} from "react-icons/fa";
import {
  FooterContainer,
  FooterWrap,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  SocialIcons,
  SocialIconLink,
} from "./Footer.style";

const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to="/">MetaLife</SocialLogo>
            <SocialIcons>
              <SocialIconLink
                href="//docs.metalife.social/metalife "
                target="_blank"
                aria-label="Whitepaper"
                rel="noopener noreferrer"
              >
                <FaReadme />
              </SocialIconLink>

              <SocialIconLink
                href="//www.discord.gg/Pwy6j63sTV"
                target="_blank"
                aria-label="Discord"
                rel="noopener noreferrer"
              >
                <FaDiscord />
              </SocialIconLink>
              <SocialIconLink
                href="//www.twitter.com/metalifesocial"
                target="_blank"
                aria-label="Twitter"
                rel="noopener noreferrer"
              >
                <FaTwitter />
              </SocialIconLink>
              <SocialIconLink
                href="//www.linkedin.com/company/metalife-social"
                target="_blank"
                aria-label="Linkedin"
                rel="noopener noreferrer"
              >
                <FaLinkedin />
              </SocialIconLink>
              <SocialIconLink
                href="//www.facebook.com/MetalifeSocial"
                target="_blank"
                aria-label="Facebook"
                rel="noopener noreferrer"
              >
                <FaFacebook />
              </SocialIconLink>
            </SocialIcons>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
