import tm1 from '../../images/team-bj.jpg';
import tm2 from '../../images/team-cy.jpg';
import tm4 from '../../images/team-lh.jpg';
import tm5 from '../../images/team-vd.jpg';
import tm6 from '../../images/team-kn.jpg';
import tm7 from '../../images/team-lm.jpg';
import tm8 from '../../images/team-du.jpg';
import tm9 from '../../images/team-kz.jpg';
import tm10 from '../../images/team-ij.jpg';
import tm11 from '../../images/team-sw.jpg';
import tm12 from '../../images/team-mz.jpg';
import tm13 from '../../images/team-sm.jpg';
import tm14 from '../../images/team-ri.jpg';
import tm15 from '../../images/team-lm1.jpg';
import tm16 from '../../images/team-sx.png';
import tm17 from '../../images/team-jl.png';
import tm18 from '../../images/team-cc.jpg';

import advisor1 from '../../images/advisor-dm.jpg';
import advisor2 from '../../images/advisor-dl.jpg';
import advisor3 from '../../images/advisor-mm.jpg';
import advisor4 from '../../images/advisor-hw.jpg';
import advisor5 from '../../images/advisor-hl.jpg';
import advisor6 from '../../images/advisor-ey.jpg';

export const teamData = [
  {
    img: tm1,
    alt: 'MetaLife BE',
    name: 'Brian J. Esposito',
    link: '//www.linkedin.com/in/brianjesposito/',
    desc: 'Strategy Lead \n Co-Founder',
  },
  {
    img: tm11,
    alt: 'MetaLife SW',
    name: 'Scott Wang',
    link: '//www.linkedin.com/in/metascottwang',
    desc: 'Product & \n Architecture Lead'
  },
/*  {
    img: tm2,
    alt: 'MetaLife DA',
    name: 'Derisha Aryawan',
    link: '//www.linkedin.com/in/derishaaryawan/',
    desc: 'Social Media Lead'
  },*/
  {
    img: tm4,
    alt: 'MetaLife LH',
    name: 'Lonmee Hou',
    link: '',
    desc: 'Engineering Lead'
  },
  {
    img: tm5,
    alt: 'MetaLife HL',
    name: 'Vitaly Dubinin',
    link: '//www.linkedin.com/in/counsel/',
    desc: 'Gaming Lead'
  },
  {
    img: tm7,
    alt: 'MetaLife LM',
    name: 'Lena Maslava',
    link: '//www.linkedin.com/in/lena-maslava-4a3ab1216/',
    desc: 'Community Leader'
  },
  {
    img: tm12,
    alt: 'MetaLife MZ',
    name: 'Mark Zeeshan',
    link: '//www.linkedin.com/in/m-zeeshan-khalil-09484462',
    desc: 'Tech & Member \n Support'
  },
  {
    img: tm16,
    alt: 'MetaLife SX',
    name: 'Selena Xiang',
    link: '//www.linkedin.com/in/selenaxiang23/',
    desc: 'Youth Ambassador'
  },
  {
    img: tm6,
    alt: 'MetaLife KN',
    name: 'Ken Nakamura',
    link: '//www.linkedin.com/in/ken-nakamura-5b247a225/',
    desc: 'Full-Stack Developer'
  },
  {
    img: tm8,
    alt: 'MetaLife DU',
    name: 'David Uzochukwu',
    link: '//www.linkedin.com/in/david-uzochukwu-3a3a07171/',
    desc:'Smart Contract'
  },
  {
    img: tm9,
    alt: 'MetaLife KZ',
    name: 'Konstantin Zolotarov',
    link: '',
    desc: 'Frontend'
  },
  {
    img: tm10,
    alt: 'MetaLife IJ',
    name: 'Ian David John',
    link: '',
    desc: 'Animation'
  },
  {
    img: tm13,
    alt: 'MetaLife SM',
    name: 'Shubh Maheshwari',
    link: '',
    desc: 'Community Leader'
  }
];

export const advisorData = [
/*  {
    img: advisor1,
    alt: 'MetaLife DM',
    name: 'Dan Larimer',
    link: '//www.linkedin.com/in/daniel-larimer-0a367089/',
    desc: 'Blockcchain Advisor'
  },*/
  {
    img: advisor2,
    alt: 'MetaLife DL',
    name: 'Prof David Lee',
    link: '//www.linkedin.com/in/david-lee-kuo-chuen-%E6%9D%8E%E5%9B%BD%E6%9D%83-07750baa/',
    desc:'Chairman \n Global Fintech Institute'
  },
  {
    img: advisor6,
    alt: 'MetaLife EY',
    name: 'Eric Yoon',
    link: '//www.linkedin.com/in/eric-yoon-932b8b23/',
    desc: 'Founder & CEO \n ESTV & TVK24'
  },
  {
    img: advisor3,
    alt: 'MetaLife MM',
    name: 'Michael Manfredi',
    link: '//www.linkedin.com/in/mike-manfredi/',
    desc: 'Governance Advisor'
  },
  {
    img: advisor4,
    alt: 'MetaLife HW',
    name: 'Henry Wang',
    link: '//www.linkedin.com/in/henry-wang-smartmesh-9153798/',
    desc: 'CEO \n SmartMesh'
  },
  {
    img: advisor5,
    alt: 'MetaLife HL',
    name: 'Hock Lai Chia',
    link: '//www.linkedin.com/in/chiahocklai/',
    desc: 'Co-Chairman \n Blockchain Association of Singapore'
  }
];
