import Navbar from '../Navbar/Navbar';
import LogoSrc from '../../images/metalife-logo1.png'
import { FaAppStoreIos, FaAndroid,FaBeer  } from "react-icons/fa";
import { AiFillApple  } from "react-icons/ai";
import './Hero.css'; // 引入你的 CSS 文件


import {
  HeroContainer,
  HeroContent,
  HeroItems,
  HeroH1,
  HeroP,
  HeroP1,
  HeroLink,
  HeroBtn,
  HeroIconIos,
  HeroIconAndroid,
  HeroIconDiv
} from './Hero.style';

const Hero = () => {

  return (
    <HeroContainer>
      <Navbar />
      <HeroContent>
        <HeroItems>
          <HeroH1>Decentralized Social Network Protocol</HeroH1>
          <HeroP>for Web3.0 Metaverse</HeroP>
          <img src={LogoSrc} alt="MetaLife Metaverse" />
          
 
<HeroIconDiv>

  <HeroIconIos>
          <HeroLink
            href="https://apps.apple.com/us/app/metalife-social/id1623784965"
            target="_blank"
            rel="noopener noreferrer"
          >
              <AiFillApple className="custom-icon-ios"/>
          </HeroLink>
          <HeroP1> App Store </HeroP1>
  </HeroIconIos>
    
    <HeroIconAndroid>            

            <HeroLink
              href="https://metalife-1251029933.file.myqcloud.com/apk/app-release.apk"
              // target="_blank"
              rel="noopener noreferrer"
            >
              <FaAndroid className="custom-icon-android"/> 
            </HeroLink>
            <HeroP1> Android APK </HeroP1>
  </HeroIconAndroid>

  <HeroIconAndroid>            

            <HeroLink
              href="https://testflight.apple.com/join/luqokCUq"
              // target="_blank"
              rel="noopener noreferrer"
            >
              <AiFillApple className="custom-icon-ios"/> 
            </HeroLink>
            <HeroP1> TestFlight </HeroP1>
  </HeroIconAndroid>

</HeroIconDiv>
          
          <HeroLink
            href="//www.discord.gg/Pwy6j63sTV "
            target="_blank"
            aria-label="Discord"
            title="Join our Discord server to start earning now!"
            rel="noopener noreferrer"
          >
            <HeroBtn>Join MetaLife Discord Community </HeroBtn>
          </HeroLink>

        </HeroItems>
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
