import ex1 from "../../images/socialize.png";
import ex2 from "../../images/build.png";
import ex3 from "../../images/work.png";
import ex4 from "../../images/play.png";
import ex5 from "../../images/earn.png";

export const experienceData = [
  {
    img: ex1,
    alt: "Metaverse Socialize",
    name: "Socialize",
    desc: "Private or Public \n Peer-to-Peer \n Peer-to-Pub",
  },
  {
    img: ex2,
    alt: "Metaverse Build",
    name: 'Build',
    desc: "Build your space \n Build your business \n with DAO",
  },
  {
    img: ex3,
    alt: "Metaverse Work",
    name: 'Work',
    desc: "Work with your \n community using \n DAOs & NFTs",
  },
  {
    img: ex4,
    alt: "Metaverse Play",
    name: 'Play',
    desc: "Play-to-Earn \n Game-Fi \n Liquidate your assets",
  },
  {
    img: ex5,
    alt: "Metaverse Earn",
    name: 'Earn',
    desc: "Participate-to-Earn \n in both digital \n and real world",
  },
];
