import styled from "styled-components";

export const ExperienceContainer = styled.div`
  /* width: 100vw; */
  min-height: 10vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #150f0f;
  color: #fff;
`;

export const ExperienceWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;

export const ExperienceCard = styled.div`
margin: 0 0.5rem;
width: 190px;
  margin-bottom: 1rem;
`;

export const ExperienceImg = styled.img`
  height: 460px;
  min-width: 235px;
  max-width: 100%;
  margin-bottom: 1rem;

  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
  }
`;

export const ExperienceHeading = styled.h1`
  font-size: clamp(2rem, 2.5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
`;

export const ExperienceInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ExperienceName = styled.h2`
  font-weight: 150;
  font-size: 1.5rem;
  white-space: pre-line;
  margin-bottom: 1rem;
`;

export const ExperienceTitle = styled.p`
  font-size: 1rem;
  white-space: pre-line;
  margin-bottom: 1rem;
  font-weight: 150;
`;
