import React from "react";
import {
  ExperienceContainer,
  ExperienceWrapper,
  ExperienceHeading,
  ExperienceName,
  ExperienceTitle,
  ExperienceCard,
  ExperienceImg,
  ExperienceInfo,
} from "./Experience.style";

const Experience = ({ heading, data }) => {
  return (
    <ExperienceContainer>
      <ExperienceHeading>Experience &nbsp;
        <span className='GradientText'> MetaLife</span>
      </ExperienceHeading>
      <ExperienceWrapper>
        {data.map((screen, index) => {
          return (
            <ExperienceCard key={index}>
              <ExperienceInfo>
                <ExperienceName>{screen.name}</ExperienceName>
                <ExperienceImg src={screen.img} alt={screen.alt} />
                <ExperienceTitle>{screen.desc}</ExperienceTitle>
              </ExperienceInfo>
            </ExperienceCard>
          );
        })}
      </ExperienceWrapper>
    </ExperienceContainer>
  );
};

export default Experience;
