import styled from "styled-components";

export const PartnerContainer = styled.div`
  /* width: 100vw; */
  min-height: 10vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #150f0f;
  color: #fff;
`;

export const PartnerWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;

export const PartnerCard = styled.a`
margin: 0 3rem;
width: 120px;
  margin-bottom: 1rem;
`;

export const PartnerImg = styled.img`
  height: auto;
  min-width: 130px;
  max-width: 100%;
  margin-bottom: 1rem;
`;

export const PartnerHeading = styled.h1`
  font-size: clamp(2rem, 2.5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
`;

export const PartnerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const PartnerName = styled.h2`
  font-weight: 150;
  font-size: 1.5rem;
  white-space: pre-line;
  margin-bottom: 1rem;
`;

export const PartnerTitle = styled.p`
  font-size: 1rem;
  white-space: pre-line;
  margin-bottom: 1rem;
  font-weight: 150;
`;
