import styled from "styled-components";

export const PeopleContainer = styled.div`
  /* width: 100vw; */
  min-height: 10vh;
  padding: 5rem calc((100vw - 1300px) / 2);
  background: #150f0f;
  color: #fff;
`;

export const PeopleWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 auto;
`;

export const PeopleCard = styled.a`
  margin: 0 2rem;
  width: 170px;
  border-radius: 10px;
  text-decoration: none;
  background: #272b2a;
  margin-bottom: 1rem;
`;

export const PeopleImg = styled.img`
  height: 150px;
  min-width: 150px;
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 1rem;

  &:hover {
    transform: scale(1.06);
    transition: all 0.3s ease-out;
  }
`;

export const PeopleHeading = styled.h1`
  font-size: clamp(2rem, 2.5vw, 3rem);
  text-align: center;
  margin-bottom: 5rem;
`;

export const PeopleInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const PeopleName = styled.h2`
  font-weight: 400;
  font-size: 1.2rem;
  white-space: pre-line;
  margin-bottom: 1rem;
  color: #fff;
`;

export const PeopleTitle = styled.p`
  font-size: 1rem;
  font-weight: 100;
  white-space: pre-line;
  margin-bottom: 1rem;
  color: #fff;
`;
