import React from "react";
import {
  PeopleContainer,
  PeopleWrapper,
  PeopleHeading,
  PeopleName,
  PeopleTitle,
  PeopleCard,
  PeopleImg,
  PeopleInfo,
} from "./People.style";

const People = ({ heading, data }) => {
  return (
    <PeopleContainer>
      <PeopleHeading>
        <span className="GradientText">{heading}</span>
      </PeopleHeading>
      <PeopleWrapper>
        {data.map((person, index) => {
          return (
            <PeopleCard
              key={index}
              href={person.link}
              target="_blank"
              aria-label="Linkedin"
              rel="noopener noreferrer"
            >
              <PeopleImg src={person.img} alt={person.alt} />
              <PeopleInfo>
                <PeopleName>{person.name}</PeopleName>
                <PeopleTitle>{person.desc}</PeopleTitle>
              </PeopleInfo>
            </PeopleCard>
          );
        })}
      </PeopleWrapper>
    </PeopleContainer>
  );
};

export default People;
